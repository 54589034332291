import { Radio } from 'antd'
import styles from './view-toggler.module.scss'
import dashboardStore from '../../dashboard-store'
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { useGlobaContext } from '../../../../global/globa-provider';
import navbarStore from '../../../navbar/navbar.store';

export default function ViewToggler() {

    const {scCalendar, setCalendarView, calendarView, view, setView} = dashboardStore()
    const {isSmallScreen} = useGlobaContext()
    const [toggleOptions, setToggleOptions] = useState([])
    const {setSidebarOpen} = navbarStore()
    const [listView, setListView] = useState(isMobile ? 'listDay' : 'listWeek')

    const listViewOptions = [
        {
            label: 'Day',
            value: 'listDay'
        },
        {
            label: 'Week',
            value: 'listWeek'
        },
    ]

    useEffect(() => {
        let initView = isMobile ? "list" : isSmallScreen ? "timeGridDay" : "timeGridWeek"
        setView(initView)
    }, [])

    useEffect(() => {
        if (scCalendar){
            if (view === 'list'){
                setCalendarView(listView)
                scCalendar.setOption('view', listView)
            } else {
                setCalendarView(view)
                scCalendar.setOption('view', view)
            }
        }
    }, [view, scCalendar])

    useEffect(() => {
        if (isSmallScreen) {
            setToggleOptions([
                {
                    label: 'Planner',
                    value: 'timeGridDay'
                },
                {
                    label: 'Summary',
                    value: 'list'
                },
            ])
        } else {
            setToggleOptions([
                {
                    label: 'Planner',
                    value: 'timeGridWeek'
                },
                {
                    label: 'Summary',
                    value: 'list'
                },
            ])
        }
    }, [isSmallScreen])

    const onToggleClick = (e) => {
        let value = e.target.value
        setView(value)

        if (value === 'list'){
            if (isMobile){
                value = 'listDay'
                setListView(value)
            } else {
                value = 'listWeek'
                setListView(value)
            }
        }
        if (scCalendar) {
            scCalendar.setOption('view', value)
            setCalendarView(value)
            if (isMobile){
                setSidebarOpen(false)
            }
        }
    }

    const onListToggleClick = (e) => {
        let value = e.target.value
        setListView(value)

        if (scCalendar) {
            scCalendar.setOption('view', value)
            setCalendarView(value)
            if (isMobile){
                setSidebarOpen(false)
            }
        }
    }

    return (
        <div className='flex flex-col w-full gap-2'>
            {toggleOptions.length && <Radio.Group className={styles.buttonGroup} value={view} size="large">
                {toggleOptions.map((o, i) => (
                    <Radio.Button key={i} value={o.value} className={styles.buttonToggle} onClick={onToggleClick}>{o.label}</Radio.Button>
                ))}
            </Radio.Group>}

            {view === 'list' && <Radio.Group className={styles.buttonGroup} value={listView} size="large">
                {listViewOptions.map((o, i) => (
                    <Radio.Button key={i} value={o.value} className={styles.buttonToggle} onClick={onListToggleClick}>{o.label}</Radio.Button>
                ))}
            </Radio.Group>}
        </div>
    )
}
