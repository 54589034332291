import React, { useRef, useState, useEffect } from 'react';
import sc from 'sc-calendar'
import TimeGrid from 'sc-calendar/plugin/time-grid'
import Interaction from 'sc-calendar/plugin/interaction'
import List from 'sc-calendar/plugin/list'
import dashboardStore from '../dashboard-store';
import styles from './sc-calendar.module.scss'
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import { isMobile } from 'react-device-detect';
import { useGlobaContext } from '../../../global/globa-provider';
import navbarStore from '../../navbar/navbar.store';
import firebaseFunctions from '../../../firebase/firebase-functions';
import useGoogleMapsApi from '../../../utils/google/useGoogleMapsApi';

const ScCalendar = ({ tripOpts }) => {
    const firstRender = useRef(false)
    const scRef = useRef()
    const {isSmallScreen} = useGlobaContext()
    const {chosenDate, tripData, scCalendar, setScCalendar, tripUsers, calendarView, setCalendarView, isVisitor} = dashboardStore()
    const {setSidebarOpen} = navbarStore()
    const [scLoading, setScLoading] = useState(false)
    const googleMapsApi = useGoogleMapsApi()

    useEffect(() => {
        if (firstRender.current) return
        if (tripOpts && googleMapsApi) {
            firstRender.current = true
            let plugins = []
            if (isMobile) {
                tripOpts.view = 'listWeek'
                tripOpts.isMobile = isMobile
            }
            if (isVisitor){
                tripOpts.editable = false
                plugins = [List, TimeGrid]
            } else {
                tripOpts.editable = true
                plugins = [List, TimeGrid, Interaction]
            }
            tripOpts.functions = firebaseFunctions
            tripOpts.googleMapsApi = googleMapsApi
            tripOpts.tripUsers = tripUsers
            setScCalendar(new sc({
                target: scRef.current,
                props: {
                    options: tripOpts,
                    plugins
                }
            }));
        }
    }, [googleMapsApi, tripOpts])

    useEffect(() => {
        if (scCalendar) {
            scCalendar.setOption('date', new Date(chosenDate))
        }
    }, [chosenDate, scCalendar])
    
    useEffect(() => {
        if (scCalendar && tripData){
            setScLoading(true)
            let options = Object.keys(tripData.tripOpts)

            options.forEach((o) => scCalendar.setOption(`${o}`, tripData.tripOpts[o]))
            scCalendar.setOption('tripUsers', tripUsers)
            setScLoading(false)
        }
    }, [scCalendar, tripData, tripUsers])
    
    useEffect(() => {
        if (isSmallScreen && calendarView === 'timeGridWeek'){
            scCalendar.setOption('view', 'timeGridDay')
            setCalendarView('timeGridDay')
        } else if (!isSmallScreen && calendarView === 'timeGridDay') {
            scCalendar.setOption('view', 'timeGridWeek')
            setCalendarView('timeGridWeek')
        }
        if (isSmallScreen) {
            setSidebarOpen(false)
        }
    }, [isSmallScreen])

    if (scLoading){
        return (
            <LoadingSpinner />
        )
    } else {
        return (
            <div className={`${styles.scCalendarContainer} pl-4 pr-4 flex-1`} ref={scRef} />
        )
    }
};

export default ScCalendar