import { Button, Modal } from "antd";
import dashboardStore from "../../dashboard-store";
import { useEffect, useState } from "react";
import { dbTripUpdateField } from "../../../../firebase/db/trips";

export default function ShareTrip() {

    const {tripData, tripId} = dashboardStore()
    const [shareTripModal, setShareTripModal] = useState(false)

    const onShareTripChange = (event) => {
        event.preventDefault()
        if (tripData.public){
            dbTripUpdateField(tripId, {
                public: false
            })
        } else {
            setShareTripModal(true)
        }
    }

    const onConfirm = () => {
        dbTripUpdateField(tripId, {
            public: true
        })
        setShareTripModal(false)
    }

    return (
        <>
            {tripData && <div className='flex pb-2 w-full gap-2 items-center pl-3'>
                <span className='font-semibold'>Share Trip: </span>
                <label className="relative items-center cursor-pointer">
                    <input type="checkbox" checked={tripData.public} onChange={onShareTripChange} className="sr-only peer"/>
                    <div className="w-14 h-7 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                </label>
            </div>}
            <Modal
                open={shareTripModal} 
                closeIcon={false}
                title="Share Trip"
                maskClosable={false}
                footer={[
                    <Button type="default" key='secondary-action' onClick={() => setShareTripModal(false)} >
                        Do not share
                    </Button>,
                    <Button type="primary" key='primary-action' onClick={onConfirm}>
                        Share
                    </Button>,
                ]}
            >
                Anyone with the link can view your trip, but will not be able to edit. Share this trip?
            </Modal>
        </>
    )
}
