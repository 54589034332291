import { useEffect, useState } from 'react';
import globaStore from '../../global/globastore';
import styles from './home.module.scss'
import LoadingSpinner from '../shared/components/loading-spinner/loading-spinner';
import HomeMain from './home-main/home-main';
import HomeTrips from './home-trips/home-trips';
import HomeShowcase from './home-showcase/home-showcase';
import Footer from './footer/footer';

const Home = () => {

    document.title = `Palwego`
    const {currentUser, trips} = globaStore()

    return (
        <div id={styles.schavelHomepage}>
            <div className={styles.homepageContainer}>
                <HomeMain></HomeMain>
                {currentUser && <HomeTrips></HomeTrips>}
                <HomeShowcase></HomeShowcase>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Home;