import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Terms of Service</h1>
          <p className="mt-2 text-lg text-gray-600">Last updated: October 28, 2024</p>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-6 space-y-8">
            {/* Terms Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Terms</h2>
              <p className="text-gray-700">
                By accessing this website, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws, and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
              </p>
            </section>

            {/* Copyright Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Content Copyright Policy</h2>
              <p className="text-gray-700">
                The site design, logo, video content subject to copyright © 2024-present | Palwego
              </p>
            </section>

            {/* Memberships Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Memberships</h2>
              <div className="space-y-4 text-gray-700">
                <div>
                  <h3 className="font-medium mb-2">Billing</h3>
                  <p>
                    Currently Palwego is completely free to use, and users will not be charged in any capacity for using the services provided by Palwego, nor will existing users be charged if said services do end up behind a paywall in the future.
                  </p>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Sign-up</h3>
                  <p>
                    By signing up for Palwego, you grant Palwego permission to send email communications to the email address associated with your account provider. If you receive promotional emails from Palwego, you can unsubscribe by clicking the bottom Unsubscribe or Opt-Out link at the bottom of every email.
                  </p>
                </div>
              </div>
            </section>

            {/* Disclaimer Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Disclaimer</h2>
              <p className="text-gray-700">
                The materials on the Palwego website are provided "as is". Palwego makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Palwego does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet website or otherwise relating to such materials or on any sites linked to this site.
              </p>
            </section>

            {/* Limitations Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Limitations</h2>
              <p className="text-gray-700">
                In no event shall Palwego be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Palwego Internet site, even if Palwego or a Palwego authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
              </p>
            </section>

            {/* Intellectual Property Rights Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Intellectual Property Rights</h2>
              <p className="text-gray-700">
                Palwego is committed to protecting intellectual property rights. Palwego strictly follows DMCA guidelines for unauthorized use of copyrighted material. Any inquiries regarding the reproduction of the content on this site must be directed to the party holding the proprietary rights to the specified content. You shall not distribute, publish, transmit, modify, display or create derivative works from material obtained with this service. To file a notice of copyright infringement with Palwego, you will need to provide a written communication that follows the guidelines set in Section 512(c)(3) of the Digital Millennium Copyright Act (the "DMCA").
              </p>
            </section>

            {/* Governing Law Section */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Governing Law</h2>
              <p className="text-gray-700">
                Any claim relating to the Palwego website shall be governed by the laws of the State of California without regard to its conflict of law provisions. General Terms and Conditions apply to the Use of a Web Site.
              </p>
            </section>
          </div>
        </div>

        {/* Footer Section */}
        <div className="mt-8 text-center text-gray-500">
          <p>© 2024 Palwego. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;