import { Button } from 'antd'
import styles from './home-showcase.module.scss'
import { useState } from 'react';
import { useGlobaContext } from '../../../global/globa-provider';
import ReactGA from 'react-ga4';
import { httpsCallable } from 'firebase/functions';
import firebaseFunctions from '../../../firebase/firebase-functions';
import globaStore from '../../../global/globastore';

const HomeShowcase = () => {

    const {toastNotification} = useGlobaContext()
    const {currentUser} = globaStore()

    const [formData, setFormData] = useState({
        name: currentUser ? currentUser.displayName : '',
        email: currentUser ? currentUser.email : '',
        details: ''
    });

    const [status, setStatus] = useState({
        loading: false,
        success: false,
        error: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        details: ''
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            name: '',
            email: '',
            details: ''
        };

        // Name validation
        if (!formData.name.trim()) {
            console.log('no name')
            newErrors.name = 'Name is required';
            isValid = false;
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }

        // Details validation
        if (!formData.details.trim()) {
            newErrors.details = 'details is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        setStatus({ loading: true, success: false, error: '' });

        ReactGA.event({
            category: 'Button Click',
            action: 'sending feedback',
            label: 'Feedback'
        })
        
        const sendFeedback = httpsCallable(firebaseFunctions, 'sendFeedback');
        sendFeedback({
            name: formData.name,
            email: formData.email,
            details: formData.details,
            verified: !!currentUser?.email
        }).then(() => {
            setStatus({ loading: false, success: true, error: '' });
            setFormData({ 
                name: currentUser ? currentUser.displayName : '',
                email: currentUser ? currentUser.email : '',
                details: ''
             }); // Reset form
            toastNotification('success', {message: 'Thank you for your feedback!'});
        })
        .catch((err) => {
            setStatus({
                loading: false,
                success: false,
                error: 'Failed to send feedback. Please try again later.'
            });
            toastNotification('error', {message: 'Failed to submit feedback. Please try again or email us directly!'});

            ReactGA.event({
                category: 'Error',
                action: 'Feedback Form Error',
                label: err.message || 'Failed to send feedback'
            });    
        })
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <img src='https://storage.googleapis.com/palwego-static/home/Calendar.png'></img>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Say goodbye to Word docs and spreadsheets</h1>
                        <span className={styles.description}>Planning trips has never been easier. Organize your itinerary with drag, drop and resize features. Discover activities and must-see destinations without the need to switch tabs. Personalize your events by assigning categories and adding your favorite colors</span>
                    </div>
                </div>
            </div>

            <div className={`${styles.wrapper} ${styles.reversed}`}>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <img src='https://storage.googleapis.com/palwego-static/home/invite.jpg'></img>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Planning can be fun!</h1>
                        <span className={styles.description}>Collaborate with friends and family to plan your trip together, with live updates reflecting changes in real-time. Not in the mood to plan the entire trip yourself? Simply request their itineraries and customize them to make your own</span>
                    </div>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <img src='https://storage.googleapis.com/palwego-static/home/mobile.jpg'></img>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Travel hassle free</h1>
                        <span className={styles.description}>Travel with ease and confidence. Keep every detail of your trip at your fingertips with our summary view, ensuring you stay organized every step of the way</span>
                    </div>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.container + ' text-center'}>
                    <h1 className={styles.title}>Contact Us</h1>
                    <span className={styles.description}>
                        Need help, have an inquiry or want to share some feedback? <br></br>
                        Email palwegollc@gmail.com, or fill out the form below.</span>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.feedbackContainer}>
                    <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="flex flex-col">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={`w-full ${errors.name ? 'sc-input-error' : ''}`}
                                />
                                {errors.name && <span className="text-red-500 text-sm pl-4">{errors.name}</span>}
                            </div>

                            <div className="flex flex-col">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`w-full ${errors.email ? 'sc-input-error' : ''}`}
                                />
                                {errors.email && <span className="text-red-500 text-sm pl-4">{errors.email}</span>}
                            </div>

                            <div className="flex flex-col">
                                <textarea
                                    name="details"
                                    placeholder="Tell us more..."
                                    value={formData.details}
                                    onChange={handleChange}
                                    className={`w-full min-h-[150px] ${errors.details ? 'sc-input-error' : ''}`}
                                />
                                {errors.details && <span className="text-red-500 text-sm pl-4">{errors.details}</span>}
                            </div>

                        <Button 
                            type="primary" 
                            htmlType="submit"  
                            className="w-full"
                            disabled={status.loading}
                            loading={status.loading}

                        >
                            Submit
                        </Button>
                    </form>
                </div>
            </div>
            <div className='pb-6'></div>
        </>
    )

}

export default HomeShowcase