import { useNavigate } from 'react-router-dom'
import styles from './footer.module.scss'

const Footer = () => {

    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <span>Copyright © 2024 Palwego, All rights reserved.</span>
            <div className={styles.itemsContainer}>
                <span onClick={() => navigate('/terms-of-service')}>Terms of Services</span>
                <span onClick={() => navigate('/privacy-policy')}>Privacy Policy</span>
            </div>
        </div>
    )
}

export default Footer