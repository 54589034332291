import { Collapse, Modal, Tooltip } from 'antd';
import style from './trip-price-details.module.scss'
import { useEffect, useState } from 'react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import currency from 'currency.js';
import globaStore from '../../../../../global/globastore';
import { array_move } from '../../../../../utils/array-helpers/array-helpers';
import { formatToCurrency } from '../../../../../utils/currency/currency';

const TripPriceDetails = ({tripPricePP, modalOpen, setModalOpen}) => {

    const [detailItems, setDetailItems] = useState([])
    const [payItems, setPayItems] = useState([])
    const [currentTab, setCurrentTab] = useState('details')
    const {currentUser} = globaStore()

    const changeTab = () => {
        console.log('trigger')
        if (currentTab === 'details'){
            setCurrentTab('pay')
        } else {
            setCurrentTab('details')
        }
    }

    const onModalCancel = () => {
        setModalOpen(false)
        setCurrentTab('details')
    }

    useEffect(() => {
        let currentUserIdx = tripPricePP.findIndex((user) => user.email === currentUser.email)

        if (currentUserIdx > -1){
            array_move(tripPricePP, currentUserIdx, 0)
        }
        setDetailItems(tripPricePP.map((u, i) => {
            return {
                key: i,
                label: 
                    <div className={`flex justify-between ${style.tripPricePPHeader}`}>
                        <span>{u.displayName || u.email}</span>
                        <span>{formatToCurrency(u.totalPrice)}</span>
                    </div>,
                children: 
                    <div className={style.tripPricePPContent}>
                        {Object.keys(u.events).map((id, idx) => (
                            <div className={`flex justify-between ${style.tripPricePPRow}`} key={idx}>
                                <span>{u.events[id].title}</span>
                                <span>{formatToCurrency(u.events[id].pricePP)}</span>
                            </div>
                        ))}
                    </div>
            }
        }))

        setPayItems(tripPricePP.map((u, i) => {
            let paidUsersMap = {};
            Object.values(u.events).forEach((event) => {
                if (event.paidBy?.email && event.pricePP > 0){
                    let user = event.paidBy.email
                    if (paidUsersMap[user]){
                        paidUsersMap[user].value = currency(paidUsersMap[user].value).add(event.pricePP).value
                    } else {
                        paidUsersMap[user] = {}
                        paidUsersMap[user].value = currency(0).add(event.pricePP).value
                        paidUsersMap[user].displayName = event.paidBy.displayName
                        paidUsersMap[user].email = event.paidBy.email
                    }
                }
            }) 
            
            return {
                key: i,
                label: 
                    <div className={`flex justify-between ${style.tripPricePPHeader}`}>
                        <span>{currentUser.email === u.email ? 'You owe:' : `${u.displayName || u.email} owes:`}</span>
                    </div>,
                children: 
                    <div className={style.tripPricePPContent}>
                        {Object.values(paidUsersMap).map((user, idx) => (
                            <div className={`flex justify-between ${style.tripPricePPRow}`} key={idx}>
                                <Tooltip placement="top" title={user.email}>
                                    <span>{user.displayName || user.email}</span>
                                </Tooltip>
                                <span>{formatToCurrency(user.value)}</span>
                            </div>
                        ))}
                    </div>
            }
        }))
    }, [tripPricePP])

    return (
        <Modal
            destroyOnClose={true}
            open={modalOpen}
            footer={false}
            closeIcon={false}
            onCancel={onModalCancel}
            width={650}
        >
            <div className={style.modalHeader}>
                <input 
                    className={style.checkbox} 
                    type="checkbox" 
                    id="reg-log" 
                    checked={currentTab === 'pay'}
                    onChange={changeTab}
                    />
                <label className={style.formButtonContainer} htmlFor="reg-log">
                    <div className={style.titleButton + " " + (currentTab === 'details'? style.selectedTab : '')} onClick={() => setCurrentTab('details')}>Details </div>
                    <div className={style.titleButton + " " + (currentTab === 'pay'? style.selectedTab : '')} onClick={() => setCurrentTab('pay')}>Pay</div>
                </label>
            </div>

            {currentTab === 'details' && <Collapse 
                items={detailItems} 
                accordion={true} 
                expandIconPosition="end" 
                className={style.tripPriceCollapse}
                expandIcon={({ isActive }) => isActive ?  <IconChevronUp/> : <IconChevronDown />}
            />}
            {currentTab === 'pay' && <Collapse 
                items={payItems} 
                accordion={true} 
                expandIconPosition="end" 
                className={style.tripPriceCollapse}
                expandIcon={({ isActive }) => isActive ?  <IconChevronUp/> : <IconChevronDown />}
            />}

        </Modal>
    )
}

export default TripPriceDetails