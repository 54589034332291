import AirDatepicker from "air-datepicker"
import localeEn from 'air-datepicker/locale/en'
import styles from './date-config.module.scss'
import { useEffect, useRef, useState } from "react"
import dashboardStore from "../../dashboard-store"
import { formatDateMMMDDYYYY, isDateInRange } from "../../../../utils/dateFns/date-fns"
import { LoadingOutlined } from '@ant-design/icons';
import { dbUpdateTripOpt } from "../../../../firebase/db/trips"
import { IconCalendar } from "@tabler/icons-react"
import { Button, Modal } from "antd"
import { httpsCallable } from "firebase/functions"
import firebaseFunctions from "../../../../firebase/firebase-functions"

export default function DateConfig() {

    const {tripData, tripId, setChosenDate, isVisitor} = dashboardStore()
    const [oldDates, setOldDates] = useState({start: '', end: ''})
    const [updatingDates, setUpdatingDates] = useState(false)
    const [confirmDateChangeModal, setConfirmDateChangeModal] = useState(false)
    const [copyItinLoading, setCopyItinLoading] = useState(false)
    const datepickerRef = useRef(null);
    const [newDates, setNewDates] = useState({start: '', end: ''})

    const updateDatesButton = {
        content: 'Update Dates',
        className: `${styles.updateDatesButton}`,
        onClick: async (dp) => {
            setUpdatingDates(true)
            if (
                tripData.tripOpts.events.length && 
                !isDateInRange(newDates.start, oldDates.start, oldDates.end) && 
                !isDateInRange(newDates.end, oldDates.start, oldDates.end) &&
                !isDateInRange(oldDates.start, newDates.start, newDates.end) &&
                !isDateInRange(oldDates.end, newDates.start, newDates.end)
            ) {
                setConfirmDateChangeModal(true)
            } else {
                await onDatesConfirm()
            }
            setUpdatingDates(false)
            datepickerRef.current.destroy()
            datepickerRef.current = null
        }
    }

    const updateDatesButtonDisable = {
        content: 'Update Dates',
        className: `${styles.buttonDisable} ${styles.updateDatesButton}`,
        onClick: (dp) => {}
    }

    useEffect(() => {
        if (tripData){
            const {tripOpts} = tripData
            setOldDates({
                start: tripOpts.calendarStart, 
                end: tripOpts.calendarEnd
            })
        }
    }, [tripData])

    useEffect(() => {
        if (datepickerRef.current){
            if (newDates.start && newDates.end){
                if (newDates.start !== oldDates.start || newDates.end !== oldDates.end){
                    datepickerRef.current.update({
                        buttons: [updateDatesButton],
                        selectedDates: [newDates.start, newDates.end]
                    }, {silent: true})
                }
            } else if (newDates.start || newDates.end) {
                datepickerRef.current.update({
                    selectedDates: [newDates.start],
                    buttons: [updateDatesButtonDisable]
                }, {silent: true})
            }
        }
    }, [newDates, datepickerRef])

    let datepickerOpts = {
        locale: localeEn,
        inline: false,
        range: true,
        dynamicRange: true,
        buttons: [updateDatesButtonDisable],
        selectedDates: [oldDates.start, oldDates.end],
        onSelect: ({formattedDate}) => onDatesChange(formattedDate)
    }

    const onCalendarClick = () => {
        if (!datepickerRef.current) {
            datepickerRef.current = new AirDatepicker('#dates-config-datepicker', datepickerOpts)
        } else {
            datepickerRef.current.destroy()
            datepickerRef.current = null
            setNewDates({start: '', end: ''})
        }
    }

    const onDatesChange = (date) => {
        setNewDates({start: date[0], end: date[1]})
    }

    const onDatesConfirm = async () => {
        await dbUpdateTripOpt(tripId, {
            calendarStart: newDates.start,
            calendarEnd: newDates.end,
        })
        setChosenDate(newDates.start)
        setConfirmDateChangeModal(false)
    }

    const copyItin = async () => {
        setCopyItinLoading(true)
        const events = tripData.tripOpts.events.map((e) => {
            //convert firestore timestamp into date objects before passing to cloud functions
            if (e.start && e.start.toDate){
                e.start = e.start.toDate()
            }
            if (e.end && e.end.toDate){
                e.end = e.end.toDate()
            }
            return e
        })

        const cities = tripData.tripOpts.cities.map((e) => {
            //convert firestore timestamp into date objects before passing to cloud functions
            if (e.start && e.start.toDate){
                e.start = e.start.toDate()
            } else if (e.startString){
                e.start = new Date(e.startString)
            }
            if (e.end && e.end.toDate){
                e.end = e.end.toDate()
            } else if (e.endString){
                e.end = new Date(e.endString)
            }
            return e
        })

        const itinOptions = {
            events,
            oldDates,
            newDates,
            cities,
        }

        const copyitin = httpsCallable(firebaseFunctions, 'copyitin')
        copyitin(itinOptions).then(({data}) => { 
            let {events, cities} = data
            events = events.map((e) => {
                //Firebase functions do not return date objects correctly, reconstruct to date objects here in response
                e.start = new Date(e.startString)
                e.end = new Date(e.endString)
                return e
            })   

            dbUpdateTripOpt(tripId, {
                events,
                cities,
            }).then(() => {
                setCopyItinLoading(false)
                onDatesConfirm()
                window.location.reload();
            }).catch((err) => {
                console.log(err)
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
        <div className="flex flex-col gap-1 w-full items-center">
            <div className={styles.datesContainer}>
                <div className={styles.datesWrapper}>
                    <div className={`flex justify-between`}>
                        <span >Start: </span>
                        <span>{formatDateMMMDDYYYY(oldDates.start)}</span>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={`flex justify-between`}>
                        <span>End: </span>
                        <span>{formatDateMMMDDYYYY(oldDates.end)}</span>
                    </div>
                </div>
                <button 
                    className={`flex items-center justify-center text-white ${styles.dateIconContainer}`}
                    onClick={() => onCalendarClick()}
                    disabled={isVisitor}
                >
                    {updatingDates ? <LoadingOutlined /> : <IconCalendar />}
                </button>
            </div>
            <div id="dates-config-datepicker" className={styles.datesConfigDatepicker}></div>
        </div>
        <Modal
            open={confirmDateChangeModal} 
            closeIcon={false}
            title="Confirm Dates Change"
            maskClosable={false}
            footer={[
                <Button type="default" key='secondary-action' onClick={onDatesConfirm} >
                    Do not move
                </Button>,
                <Button type="primary" key='primary-action' loading={copyItinLoading} onClick={copyItin}>
                    Confirm
                </Button>,
            ]}
        >
            Your events are not in range of your new dates, would you like to move existing events over to your new dates?
        </Modal>
        </>
    )
}
