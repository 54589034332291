import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
          <p className="mt-2 text-lg text-gray-600">Last updated: October 28, 2024</p>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-6 space-y-8">
            {/* Introduction */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Introduction</h2>
              <p className="text-gray-700">
                Palwego ("we," "our," or "us") respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit our website and our practices for collecting, using, maintaining, protecting, and disclosing that information.
              </p>
            </section>

            {/* Information We Collect */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
              <div className="space-y-4 text-gray-700">
                <p>We collect several types of information from and about users of our website, including:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Personal information such as name, email address, and contact details when you register</li>
                  <li>Usage data and analytics about how you interact with our website</li>
                  <li>Device information including IP address, browser type, and operating system</li>
                  <li>Cookies and tracking technologies data</li>
                </ul>
              </div>
            </section>

            {/* How We Use Your Information */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
              <div className="space-y-4 text-gray-700">
                <p>We use the information we collect about you to:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Provide, maintain, and improve our services</li>
                  <li>Process your transactions and send related information</li>
                  <li>Send you technical notices, updates, and support messages</li>
                  <li>Respond to your comments and questions</li>
                  <li>Personalize your experience on our website</li>
                  <li>Monitor and analyze trends, usage, and activities</li>
                </ul>
              </div>
            </section>

            {/* Information Sharing */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Information Sharing and Disclosure</h2>
              <p className="text-gray-700">
                We do not sell, trade, or otherwise transfer your personally identifiable information to third parties without your consent. However, we may share your information with:
              </p>
              <ul className="list-disc pl-6 mt-4 space-y-2 text-gray-700">
                <li>Service providers who assist in our operations</li>
                <li>Law enforcement or government officials in response to a verified request</li>
                <li>Other parties in connection with a company merger, sale, or acquisition</li>
              </ul>
            </section>

            {/* Cookies */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Cookies and Tracking Technologies</h2>
              <p className="text-gray-700">
                We use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are files with small amounts of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
              </p>
            </section>

            {/* Data Security */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Data Security</h2>
              <p className="text-gray-700">
                We implement appropriate technical and organizational security measures to protect your personal information from accidental loss, unauthorized access, use, alteration, and disclosure. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.
              </p>
            </section>

            {/* Children's Privacy */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Children's Privacy</h2>
              <p className="text-gray-700">
                Our website is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.
              </p>
            </section>

            {/* California Privacy Rights */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">California Privacy Rights</h2>
              <p className="text-gray-700">
                California Civil Code Section 1798.83 permits users who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us using the information below.
              </p>
            </section>

            {/* Changes to Privacy Policy */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Changes to Our Privacy Policy</h2>
              <p className="text-gray-700">
                We reserve the right to update or change our Privacy Policy at any time. If we make material changes to this policy, we will notify you through our website or by email. Your continued use of our services after such modifications will constitute your acknowledgment of the modified Privacy Policy and agreement to abide by it.
              </p>
            </section>

            {/* Contact Information */}
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Contact Information</h2>
              <p className="text-gray-700">
                If you have any questions, concerns, or comments about our Privacy Policy, please contact us at:
              </p>
              <div className="mt-4 text-gray-700">
                <p>Palwego</p>
                <p>Email: palwegollc@gmail.com</p>
              </div>
            </section>
          </div>
        </div>

        {/* Footer Section */}
        <div className="mt-8 text-center text-gray-500">
          <p>© 2024 Palwego. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;