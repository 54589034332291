import { Button, Modal } from 'antd';
import styles from './copy-trip-modal.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react';
import { dbCreateTrip } from '../../../../firebase/db/trips';
import globaStore from '../../../../global/globastore';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../../../../firebase/analytics';
import { useNavigate } from 'react-router-dom';
import CityConfig from '../../../home/home-main/city-config/city-config';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import dashboardStore from '../../dashboard-store';
import firebaseFunctions from '../../../../firebase/firebase-functions';
import { httpsCallable } from 'firebase/functions';
import { useNavigateToTrip } from '../../../../utils/react-utils/navigate';
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en'
import { isMobile } from 'react-device-detect';

const CopyTripModal = ({copyTripModal, setCopyTripModal, setIsLoading}) => {
    const navigateToTrip = useNavigateToTrip();
    const {currentUser} = globaStore()
    const {tripData} = dashboardStore()

    const datepickerRef = useRef(null)
    const dateInputRef = useRef(null)
    const [tripName, setTripName] = useState('')
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [newStartDate, setNewStartDate] = useState(null)
    const [newEndDate, setNewEndDate] = useState(null)
    const [days, setDays] = useState(null)
    let durationDays = 0

    useEffect(() => {
        // Convert dates to UTC to avoid timezone issues
        let start = new Date(tripData.tripOpts.calendarStart);
        let end = new Date(tripData.tripOpts.calendarEnd);
        
        // Reset time to midnight UTC
        start = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0));
        end = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0, 0));
        
        // Calculate difference in days
        durationDays = ((end - start) / (1000 * 3600 * 24)) + 1;
        setDays(durationDays)
    }, [tripData]);

    useEffect(() => {
        // Initialize date picker
        if (!datepickerRef.current && dateInputRef.current) {
            datepickerRef.current = new AirDatepicker(dateInputRef.current, {
                locale: localeEn,
                autoClose: true,
                range: true,
                dynamicRange: true,
                multipleDatesSeparator: '  -  ',
                position: 'bottom center',
                isMobile,
                onSelect: ({ formattedDate }) => {
                    onDateSelect(formattedDate);
                }
            });
        }

        // Cleanup function
        return () => {
            if (datepickerRef.current) {
                datepickerRef.current.destroy();
                datepickerRef.current = null;
            }
        };
    }, []); // Empty dependency array since we only want to initialize once

    const onDateSelect = (formattedDate) => {
        if (formattedDate?.[0]) {
            const newStart = new Date(formattedDate[0]);
            const newEnd = new Date(newStart.getTime() + ((durationDays - 1) * 24 * 60 * 60 * 1000));
            const formattedEnd = new Intl.DateTimeFormat('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            }).format(newEnd);

            setNewStartDate(formattedDate[0]);
            setNewEndDate(formattedEnd);
            datepickerRef.current.selectDate([formattedDate[0], formattedEnd], {silent: true})
            datepickerRef.current.update({
                selectedDates: [formattedDate[0], formattedEnd]
            }, {silent: true})
        } 
    }; 


    const onTripNameChange = (e) => {
        setTripName(e.target.value)
    }

    const validatedInputs = () => {
        return tripName && newStartDate
    }

    const onTripCreate = async () => {
        setLoadingCreate(true)
        setIsLoading(true)

        const events = tripData.tripOpts.events.map((e) => {
            //convert firestore timestamp into date objects before passing to cloud functions
            if (e.start && e.start.toDate){
                e.start = e.start.toDate()
            }
            if (e.end && e.end.toDate){
                e.end = e.end.toDate()
            }
            e.price = {value: 0, currency: 'USD', eventUsers: []}
            return e
        })

        const cities = tripData.tripOpts.cities.map((e) => {
            //convert firestore timestamp into date objects before passing to cloud functions
            if (e.start && e.start.toDate){
                e.start = e.start.toDate()
            }
            if (e.end && e.end.toDate){
                e.end = e.end.toDate()
            }
            return e
        })

        const itinOptions = {
            events,
            oldDates: {
                start: tripData.tripOpts.calendarStart,
                end: tripData.tripOpts.calendarEnd,
            },
            newDates: {
                start: newStartDate,
                end: newEndDate
            },
            cities,
        }
        
        const copyitin = httpsCallable(firebaseFunctions, 'copyitin')
        copyitin(itinOptions).then(({data}) => { 
            let {events, cities} = data
            events = events.map((e) => {
                //Firebase functions do not return date objects correctly, reconstruct to date objects here in response
                e.start = new Date(e.startString)
                e.end = new Date(e.endString)
                return e
            })   

            let tripOpts = {
                name: tripName,
                events,
                calendarStart: newStartDate,
                calendarEnd: newEndDate,
                cities,
            }
            dbCreateTrip(currentUser, tripOpts).then((trip) => {
                logEvent(firebaseAnalytics, `analytics_tripName_${tripName}`)
                setLoadingCreate(false)
                setIsLoading(false)
                navigateToTrip(trip.id)
                window.location.reload();
            }).catch((err) => {
                console.log(err)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Modal
            classNames={({
                wrap: styles.modalWrap,
                content: styles.modalContent,
            })}
            open={copyTripModal} 
            closeIcon={true}
            onCancel={() => setCopyTripModal(false)}
            title="Create New Trip"
            width={600}
            footer={false}
        >
            <div className={styles.createTripContainer}>
                <input id="trip-name" name="trip-name" type='text' className='sc-input' placeholder='Trip name' onChange={onTripNameChange}></input>
                <input readOnly className='sc-btn-div p-2' placeholder={`Select Start Date (${days} days)`} ref={dateInputRef}></input>
                <div className={styles.homeInputsAction}>
                    <Button 
                        type="primary"
                        disabled={!validatedInputs()} 
                        className='h-full w-full rounded-3xl' 
                        onClick={() => onTripCreate()}
                        loading={loadingCreate}
                    >Create</Button>
                </div>
            </div>
        </Modal>
    )
}

export default CopyTripModal